import { useState, useEffect } from 'react'

const useDevice = () => {
	
		const [device, setDevice] = useState('desktop')
		useEffect(() => {
			if(typeof window !== undefined) {		
				const handleResize = () => {
					setDevice(window.innerWidth > 700 ? 'desktop' : 'mobile')
				}
				window.onresize = handleResize

				return () => {
					window.removeEventListener('resize', handleResize);
				}
	    }
		}, [])

		return device
	
}

export default useDevice