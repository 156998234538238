import React from "react"
import { myBlue5 } from "../colors"
import { useIntl, Link } from "gatsby-plugin-intl"
import useMenuLinks from "./useMenuLinks"

const NavLinks = props => {
  const intl = useIntl()
  const curPath = props.path
  const menuLinks = useMenuLinks({ curPath })
  return (
    <>
      {menuLinks.map(menuLink => {
        const {
          linkInfo: { name, link },
          isCurPath,
        } = menuLink
        if (isCurPath) {
          return null
        }
        return (
          <div key={name}>
            <Link className="text-white" to={link}>
              {intl.formatMessage({
                id: `routes.${name}`,
              })}
            </Link>
          </div>
        )
      })}
    </>
  )
}

const Footer = props => {
  const intl = useIntl()

  return (
    <footer
      className="text-white pt-5"
      style={{ backgroundColor: myBlue5, position: "relative" }}
    >
      <div className="container" style={{ paddingTop: "150px" }}>
        <div className="row">
          <div
            className="mt-3 col-md-5"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Link to="/">
              <h4 className="text-white">METALALL CO., LTD.</h4>
            </Link>
          </div>
          <div className="mt-3 col-md-3">
            <div className="h4 ">
              {intl.formatMessage({ id: "footer.pagesName" })}
            </div>
            <NavLinks {...props} />
          </div>
          <div className="mt-3 col-md-4">
            <div className="h4">
              {intl.formatMessage({ id: "footer.contactName" })}
            </div>
            <div>
              {intl.formatMessage({ id: "footer.emailName" })}:
              info@metalall-tw.com
            </div>
            <div>
              {intl.formatMessage({ id: "footer.telName" })}: +886-4-22630855
            </div>
            <div>
              {intl.formatMessage({ id: "footer.fax" })}: +886-4-22658814
            </div>
            <address>
              {intl.formatMessage({ id: "footer.addressName" })}:{" "}
              {intl.formatMessage({ id: "footer.address" })}
            </address>
          </div>
        </div>
        <div className="text-center py-3 mt-5">
          {intl.formatMessage({ id: "companyName" })}©{" "}
          {new Date().getFullYear()} All right reserved, created by{" "}
          <a
            className="text-white"
            href="https://www.linkedin.com/in/ting-hao-chang-4b520b147/"
          >
            Ting-Hao Chang
          </a>
        </div>
      </div>
      <div className="top-right-triangle-section" style={{ top: 0 }}></div>
    </footer>
  )
}

export default Footer
