import React, { useContext } from "react"
import { Navbar, Nav, NavLink, Dropdown } from "react-bootstrap"
import { GrLanguage } from "react-icons/gr"
import {
  useIntl,
  IntlContextConsumer,
  changeLocale,
  Link,
} from "gatsby-plugin-intl"
import useMenuLinks from "./useMenuLinks"
// @ts-ignore
import companyLogo from "../../images/index/logo.jpg"
import { deviceContext } from "."

const NavItems = props => {
  const intl = useIntl()
  const curPath = props.path
  const menuLinks = useMenuLinks({ curPath })
  return (
    <>
      {menuLinks.map(menuLink => {
        const {
          linkInfo: { name, link },
          isCurPath,
        } = menuLink
        return (
          <NavLink
            key={name}
            as={Link}
            className="h5 px-3 py-0 text-dark "
            to={link}
          >
            <div
              className={isCurPath ? "cur-path-decoration" : "path-decoration"}
            >
              {intl.formatMessage({
                id: `routes.${name}`,
              })}
            </div>
          </NavLink>
        )
      })}
      <div className="ml-3">
        <Dropdown alignRight>
          <Dropdown.Toggle id="user-dropdown" as={NavLink}>
            <GrLanguage />
          </Dropdown.Toggle>
          <Dropdown.Menu className="mt-3">
            <IntlContextConsumer>
              {({ languages, language: currentLocale }) => {
                return languages.map(language => (
                  <Dropdown.Item
                    key={language}
                    onClick={() => {
                      if (currentLocale === language) {
                        return
                      }
                      changeLocale(language)
                    }}
                  >
                    {language}
                  </Dropdown.Item>
                ))
              }}
            </IntlContextConsumer>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  )
}

const Header = props => {
  const intl = useIntl()
  const device = useContext(deviceContext)

  return (
    <Navbar
      expand="lg"
      style={{
        height: "95px",
        backgroundColor: "white",
        boxShadow: "0 8px 6px -6px #999",
        padding: "0",
        margin: "0",
        width: "100%",
      }}
      fixed="top"
      variant="light"
    >
      <div className="container-lg header">
        <Link to="/">
          <Navbar.Brand className="h4 text-dark">
            <div className="brand-name">
              <div
                className="logo-container"
                style={{ width: `${device === "desktop" ? "100px" : "70px"}` }}
              >
                <img className="logo-image" src={companyLogo} />
              </div>
              <div
                style={{
                  fontSize: `${device === "desktop" ? "20px" : "14px"}`,
                }}
              >
                <div>{intl.formatMessage({ id: "companyName" })}</div>
                <div
                  style={{
                    fontSize: "10px",
                  }}
                >
                  {intl.formatMessage({ id: "subCompanyName" })}
                </div>
              </div>
            </div>
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          style={{ marginRight: "10px" }}
        />
        <Navbar.Collapse
          id="basic-navbar-nav"
          style={{
            backgroundColor: "white",
            padding: "10px",
          }}
        >
          <Nav className="ml-auto">
            <NavItems {...props} />
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  )
}

export default Header
