import { useStaticQuery, graphql } from "gatsby"
import { languages } from "../../settings"

interface UseMenuLinksArgs {
  curPath: string
}

const useMenuLinks = ({ curPath  }: UseMenuLinksArgs) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          menuLinks {
            name
            link
          }
        }
      }
    }
  `)
  const menuLinks = data.site.siteMetadata.menuLinks
  const processedMenuLinks = menuLinks.map(
    menuLink => {
      if (menuLink.link.replace('/', "") ===
      curPath
        .split("/")
        .filter(s => !(languages.includes(s) || s === ""))
        .join("")) {
          return {
            linkInfo: menuLink,
            isCurPath: true
          }
        }
      return {
        linkInfo: menuLink,
        isCurPath: false
      }
    }
  )

  let noIsCurPath = true
  for (let i = 0; i < processedMenuLinks.length; ++i) {
    if (processedMenuLinks[i].isCurPath) {
      noIsCurPath = false
    }
  }
  if (noIsCurPath) {
    processedMenuLinks[0].isCurPath = true
  }

  return processedMenuLinks
}

export default useMenuLinks
