import React, { createContext } from "react"

import Header from "./header"
import Footer from "./footer"
import "../../style/layout.scss"
import { useDevice } from "../../hooks"

export const deviceContext = createContext("desktop")
const DeviceContextProvider = deviceContext.Provider

const Layout = ({ children, ...props }) => {
  const device = useDevice()

  return (
    <>
      <DeviceContextProvider value={device}>
        <Header {...props} />
        <main style={{ minHeight: "700px" }}>{children}</main>
        <Footer {...props} />
      </DeviceContextProvider>
    </>
  )
}

export default Layout
